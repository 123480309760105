<template>
    <el-skeleton style="width:100%" :loading="isloading" animated :count="3" :rows="6">
        <div class="big-block">

            <el-col :span="6" style="padding-right: 10px">
                <div class="grid-content bg-purple">
                    <el-card class="box-left" style="height: 900px">
                        <p align="center" style="color:red">{{ titleinfo }}</p>
                        <h2 align="center">习题练习</h2>
                        <img src="https://user-picture-1257386772.cos.ap-nanjing.myqcloud.com/%E8%93%9D%E8%89%B2%E5%BA%95%E7%89%88%E6%9C%AC.jpg"
                            alt="头像">
                        <h4>考生信息: <span class="spaninfo">XX易点通驾考XXX</span></h4>
                        <h4>姓名: <span class="spaninfo">{{ user_name }}</span></h4>
                        <h4>学号: <span class="spaninfo">18903452345</span></h4>
                        <h4>题型: <span class="spaninfo">{{ titleinfo }}</span></h4>
                        <el-card shadow="hover">
                            <div slot="header" class="clearfix">
                                <span><i class="el-icon-timer"></i>倒计时</span>
                                <el-button style="float: right; padding: 3px 0" type="text"
                                    @click="clickFn">暂停</el-button>
                            </div>
                            <div style="margin-top: 40px;"></div>
                            <el-statistic ref="statistic" @finish="hilarity" format="HH:mm:ss" :value="deadline2"
                                title="距离结束：" time-indices>
                            </el-statistic>
                        </el-card>
                    </el-card>
                </div>
            </el-col>
            <el-col :span="10">
                <div class="grid-content bg-purple-light">
                    <el-card class="box-center" style="height: 900px">
                        <el-container>
                            <el-header style="height: 900px">
                                <!-- 主题部分 -->
                                <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                                    <!-- 循环后端给你的所有题 -->
                                    <div class="VSTD_box_item" v-for="(item, index) in question" :key="item.id">

                                        <!-- 1 单选 -->
                                        <!-- 2 判断 -->
                                        <!-- 3 多选 -->
                                        <!-- 4 简答 -->
                                        <div class="problem">
                                            <div class="VSTD_box_item_title"
                                                style="width: 100%;float: left; padding-bottom: 10px;">
                                                <!-- 题目的信息 -->
                                                <p style="font-weight: 700;">
                                                    <span :class="color_set"
                                                        v-html="'第' + item.sequence_number + '题: ' + item.problem"></span>
                                                </p>
                                                <br>
                                                <div v-if="item.questionType == 2">
                                                    <span :class="color_set">A.{{ item.option_a.info }}</span><br>
                                                    <span :class="color_set">B.{{ item.option_b.info }}</span>
                                                </div>
                                                <div v-else="">
                                                    <span :class="color_set">A.{{ item.option_a.info }}</span><br>
                                                    <span :class="color_set">B.{{ item.option_b.info }}</span><br>
                                                    <span :class="color_set">C.{{ item.option_c.info }}</span><br>
                                                    <span :class="color_set">D.{{ item.option_d.info }}</span>
                                                </div>
                                            </div>



                                            <!-- 如果questionType 等于1 那么他是单选题 -->
                                            <!-- 题目绑定的值是 singlevalue  -->

                                            <div v-if="item.questionType == 1">
                                                <el-form-item label="" prop="resource">
                                                    <div class="select_option">
                                                        <p>A.{{ item.option_a.info }}</p>
                                                        <p>B.{{ item.option_b.info }}</p>
                                                        <p>C.{{ item.option_c.info }}</p>
                                                        <p>D.{{ item.option_d.info }}</p>
                                                    </div>


                                                    <div class="select">
                                                        <el-divider></el-divider>
                                                        <el-row>
                                                            <el-col :span="6">
                                                                <span class="your_answer">题型: </span>
                                                                <span v-if="item.questionType == 1"><el-tag
                                                                        type="primary">单选题</el-tag></span>
                                                                <span v-if="item.questionType == 2"><el-tag
                                                                        type="warning">判断题</el-tag></span>
                                                                <span v-if="item.questionType == 3"><el-tag
                                                                        type="danger">多选题</el-tag></span>
                                                            </el-col>

                                                            <el-col :span="18">
                                                                <div>
                                                                    <span class="your_answer">请选择你的答案: </span>
                                                                    <el-radio-group v-model="singlevalue"
                                                                        class="VSTD_box_item_select"
                                                                        :disabled="canclick" size="mini"
                                                                        @input="single()">
                                                                        <el-radio-button
                                                                            :label="item.option_a.info">A</el-radio-button>
                                                                        <el-radio-button
                                                                            :label="item.option_b.info">B</el-radio-button>
                                                                        <el-radio-button
                                                                            :label="item.option_c.info">C</el-radio-button>
                                                                        <el-radio-button
                                                                            :label="item.option_d.info">D</el-radio-button>
                                                                    </el-radio-group>
                                                                </div>
                                                            </el-col>

                                                        </el-row>



                                                    </div>

                                                </el-form-item>
                                            </div>

                                            <!-- 如果questionType 等于2 那么他是判断题 -->
                                            <!-- 题目绑定的值是 judgmentvalue  -->

                                            <div v-if="item.questionType == 2">
                                                <el-form-item label="" prop="resource">

                                                    <div class="select_option">
                                                        <p>A.{{ item.option_a.info }}</p>
                                                        <p>B.{{ item.option_b.info }}</p>
                                                    </div>

                                                    <div class="select">
                                                        <el-divider></el-divider>
                                                        <el-row>
                                                            <el-col :span="8">
                                                                <span class="your_answer">题型: </span>
                                                                <span v-if="item.questionType == 1"><el-tag
                                                                        type="primary">单选题</el-tag></span>
                                                                <span v-if="item.questionType == 2"><el-tag
                                                                        type="warning">判断题</el-tag></span>
                                                                <span v-if="item.questionType == 3"><el-tag
                                                                        type="danger">多选题</el-tag></span>
                                                            </el-col>
                                                            <el-col :span="16">
                                                                <div>
                                                                    <span class="your_answer">请选择你的答案: </span>
                                                                    <el-radio-group v-model="judgmentvalue"
                                                                        class="VSTD_box_item_select"
                                                                        :disabled="canclick" size="mini"
                                                                        @input="judgment()">
                                                                        <el-radio-button :label="item.option_a.info"><i
                                                                                class="el-icon-check"></i></el-radio-button>
                                                                        <el-radio-button :label="item.option_b.info"><i
                                                                                class="el-icon-close"></i></el-radio-button>
                                                                    </el-radio-group>
                                                                </div>
                                                            </el-col>
                                                        </el-row>



                                                    </div>
                                                </el-form-item>
                                            </div>

                                            <!-- 如果questionType 等于3 那么他是多选题 -->
                                            <!-- 题目绑定的值是 ruleForm.resource[index]  -->

                                            <div v-if="item.questionType == 3">
                                                <el-form-item label="" prop="resource">
                                                    <div class="select_option">
                                                        <p>A.{{ item.option_a.info }}</p>
                                                        <p>B.{{ item.option_b.info }}</p>
                                                        <p>C.{{ item.option_c.info }}</p>
                                                        <p>D.{{ item.option_d.info }}</p>
                                                    </div>

                                                    <div class="select">
                                                        <el-divider></el-divider>
                                                        <el-row>
                                                            <el-col :span="8">
                                                                <div class="grid-content bg-purple">
                                                                    <span class="your_answer">题型: </span>
                                                                    <span v-if="item.questionType == 1"><el-tag
                                                                            type="primary">单选题</el-tag></span>
                                                                    <span v-if="item.questionType == 2"><el-tag
                                                                            type="warning">判断题</el-tag></span>
                                                                    <span v-if="item.questionType == 3"><el-tag
                                                                            type="danger">多选题</el-tag></span>
                                                                </div>
                                                            </el-col>
                                                            <el-col :span="5">
                                                                <div class="grid-content bg-purple-light">
                                                                    <span class="your_answer">请选择你的答案: </span>
                                                                </div>
                                                            </el-col>
                                                            <el-col :span="11">
                                                                <div class="grid-content bg-purple">
                                                                    <el-checkbox-group v-model="multiplevalue"
                                                                        class="VSTD_box_item_select"
                                                                        :disabled="canclick" size="mini">

                                                                        <el-checkbox-button
                                                                            :label="item.option_a.info">A</el-checkbox-button>
                                                                        <el-checkbox-button
                                                                            :label="item.option_b.info">B</el-checkbox-button>
                                                                        <el-checkbox-button
                                                                            :label="item.option_c.info">C</el-checkbox-button>
                                                                        <el-checkbox-button
                                                                            :label="item.option_d.info">D</el-checkbox-button>
                                                                    </el-checkbox-group>
                                                                </div>
                                                            </el-col>
                                                        </el-row>
                                                    </div>


                                                </el-form-item>
                                            </div>
                                        </div>




                                        <!-- 提交函数  -->

                                        <div style="height: 60px;" class="btn_click">
                                            <el-row>
                                                <el-button icon="el-icon-chat-dot-square" @click="jiqiao"
                                                    :size="btn_size">技巧</el-button>
                                                <el-button icon="el-icon-microphone" @click="yuyin" :disabled="yuyinbtn"
                                                    :size="btn_size">语音</el-button>
                                                <el-button icon="el-icon-video-play" @click="videoed" :size="btn_size"
                                                    v-if="browser_type != 'weixin'">视频</el-button>

                                            </el-row>


                                            <span :class="skill == true ? showskill : noskill">{{
                                                question[0].explains.skill.text }}</span>
                                            <el-dialog title="讲解视频" :visible.sync="centerDialogVisible" width="60%"
                                                center @open="vopen" @close="vclose">
                                                <commonVideo :videoSource="videoSource" ref="child"></commonVideo>
                                            </el-dialog>


                                            <el-main class="next_one">

                                                <el-row>
                                                    <el-col :span="6">
                                                        &nbsp;
                                                        <div class="grid-content bg-purple" style="display: none;">
                                                            <span style="font-size:30px; float:left;">
                                                                <i class="el-icon-success"
                                                                    style="color:lightgreen;"></i>{{
                                                                        zhengque }}
                                                                <i class="el-icon-error" style="color:red;"></i>{{ cuowu
                                                                }}
                                                            </span>
                                                        </div>
                                                    </el-col>
                                                    <el-col :span="6">
                                                        <div class="grid-content bg-purple-light">
                                                            <el-button type="primary" icon="el-icon-arrow-left"
                                                                style="margin-left: 25%;"
                                                                @click="shangyiti(item.questionType)"
                                                                :size="btn_size">上一题</el-button>
                                                        </div>
                                                    </el-col>
                                                    <el-col :span="9">
                                                        <div class="grid-content bg-purple">
                                                            <el-button type="primary" style="margin-left: 26%;"
                                                                @click="xiyiti(item.questionType)"
                                                                :size="btn_size">下一题<i
                                                                    class="el-icon-arrow-right el-icon--right"></i></el-button>
                                                        </div>
                                                    </el-col>
                                                    <el-col :span="3">
                                                        <div class="grid-content bg-purple">
                                                            <el-button type="warning" @click="jiaojuan"
                                                                :size="btn_size"><i
                                                                    class="el-icon-tickets">交卷</i></el-button>
                                                        </div>
                                                    </el-col>
                                                </el-row>

                                                <!-- 底座部分 -->






                                            </el-main>
                                        </div>
                                        <div :class="show_photo">
                                            <el-form-item label="">
                                                <img :src="item.picture" alt="插画"
                                                    :class="item.picture ? incrseP : downP"
                                                    @click.self="showBigImage($event)">
                                            </el-form-item>
                                            <BigImg :visible="photoVisible" :url="bigImgUrl"
                                                @closeClick="() => { photoVisible = false }"></BigImg>
                                        </div>

                                    </div>

                                </el-form>
                            </el-header>

                        </el-container>


                    </el-card>
                </div>
            </el-col>
            <el-col :span="8" style="padding-left: 10px">
                <div class="grid-content bg-purple">
                    <el-card class="box-right" style="height: 900px">

                        <el-card class="box-card">
                            <!-- <el-tag v-for="(item, index) in tagstatus" :key="index" @click="changesubject(index)"
                                class="tag_set" hit :type="item" size="btn_size">
                                <span>
                                    {{ index + 1 }}
                                </span>
                            </el-tag> -->
                            <tbody style="display:table">
                                <tr v-for="(item, index) in tagstatus" v-if="index % 10 == 0">
                                    <el-tag :key="i" @click="changesubject(i - 1)" hit :type="tagstatus[i - 1]"
                                        v-for="i in range(index, index + 10)" class="tag_set"
                                        v-if="tagstatus[i - 1] != undefined">
                                        <span>
                                            {{ i }}
                                        </span>
                                    </el-tag>
                                </tr>

                            </tbody>
                            <!-- 
                            <tbody>
                                <tr v-for="(chapter,index) in chapterList" v-if="index%4==0">
 
                                    <th v-for="i in 4" v-if="chapterList[i-1+index] != null">

                                    {{ chapterList[i-1+index].name}}

                                    </th>

                                </tr>
                            </tbody> -->

                        </el-card>


                        <el-divider class="fenge"></el-divider>
                        &nbsp;
                        <div>
                            <span>
                                <span>颜色说明:</span>
                                <el-tag type="info" hit style="margin-right: 50px; margin-left: 20px;"
                                    :size="btn_size">未答题</el-tag>
                                <el-tag type="success" hit style="margin-right: 50px" :size="btn_size">正确题</el-tag>
                                <el-tag type="danger" hit :size="btn_size">错误题</el-tag>

                                <el-button type="primary" icon="el-icon-refresh" :size="btn_size" style="float: right"
                                    @click="refresh"></el-button>
                            </span>
                        </div>

                        <el-divider class="fenge"></el-divider>
                        <div class="lunbotu">
                            正确率
                            <el-progress :text-inside="true" :stroke-width="jindu_gao" :percentage="zhengquelv()"
                                status="success"></el-progress>
                            <br />
                            完成率
                            <el-progress :text-inside="true" :stroke-width="jindu_gao"
                                :percentage="datilv()"></el-progress>
                        </div>


                        <div class="block">
                            <el-carousel height="220px">
                                <el-carousel-item v-for="item in lunbotu" :key="item">
                                    <img :src="item" alt="简介" style="width:100%; height: 100%;">
                                </el-carousel-item>
                            </el-carousel>
                        </div>

                    </el-card>
                </div>
            </el-col>
        </div>
    </el-skeleton>
</template>

<script>
import commonVideo from '../components/commonVideo.vue'
import BigImg from "./bigimg.vue"
import { parcticeinfo_weixin, answer_question_pc } from '../api'
import DevicePixelRatio from '../utils/view_init';


export default {
    components: {
        commonVideo,
        BigImg,
    },
    data() {
        return {
            photoVisible: false,
            bigImgUrl: "",
            incrseP: 'Apirture',
            downP: 'pirture',
            noskill: 'noskill',
            showskill: 'showskill',
            iconstatus1: 'iconstatus1',
            iconstatus2: 'iconstatus2',
            skill: false,
            yuyinbtn: false,
            centerDialogVisible: false,
            judgmentvalue: '',
            singlevalue: '',
            singlevalueicon: '',
            multiplevalue: [],
            multipleanswer: [],
            canclick: false,
            zhengque: 0,
            cuowu: 0,
            pc_key: '',
            lunbotu: [
                'https://user-picture-1257386772.cos.ap-nanjing.myqcloud.com/%E5%88%9B%E5%A7%8B%E4%BA%BA%E4%BB%8B%E7%BB%8D.jpg'
            ],

            sum_question: [],
            question: [{ "option_a": { "info": "对", "correct": true }, "option_b": { "info": "错", "correct": false }, "option_c": { "info": "", "correct": false }, "option_d": { "info": "", "correct": false }, 'explains': { 'skill': '' } }],

            //控制是否题目图片

            show_photo: 'box',

            // 存储用户的答案回显
            sub_value: {},

            // 存储tag标签的状态
            tagstatus: [],

            // 请求接口成功前使用骨架屏
            isloading: true,

            titleinfo: '',

            user_name: '',
            deadline2: Date.now() + 1000 * 60 * 45,
            stop: true,
            ruleForm: {
                resource: ['A', '', [], []],				//每一道题的值
            },
            videoSource: '',
            screenWidth: document.body.clientWidth,
            btn_size: '',
            jindu_gao: 26,
            this_code: '',
            again_sub: {},
            // 打错题后设置关键字颜色
            color_set: 'color_set_o',
            // 区别浏览器类型
            browser_type: ''
        };
    },
    methods: {
        isWeixinBrowser() {
            console.log(this.browser_type)
            if (this.browser_type === 'weixin'){
                return false
            } else {
                return true
            }
        },
        showBigImage(e) {//点击图片函数，点击后，把photoVisible设置成true
            if (e != "") {
                this.photoVisible = true;
                this.bigImgUrl = e.currentTarget.src;
            }
        },
        hilarity() {
            this.$alert('考试规定时间已到！', '提示信息', {
                confirmButtonText: '确定',
            })
        },
        clickFn() {
            this.$refs.statistic.suspend(this.stop);
            this.stop = !this.stop;
        },
        changesubject(item) {
            this.question = [this.sum_question[item]]
            this.nextone()
        },
        change() {
            this.$forceUpdate()
        },
        jiqiao() {
            this.skill = !this.skill
            if (this.skill === true) {
                console.log(this.dialog)
                var myAudio = new Audio();
                myAudio.preload = true;
                myAudio.controls = true;
                myAudio.src = this.question[0].skill_mp3; // 每次读数组最后一个元素
                myAudio.play();
            }

        },
        jiaojuan() {
            let s = '共做答题 ' + Object.keys(this.sub_value).length + ' 个, 正确: ' + this.zhengque + ' 个, 错误： ' + this.cuowu + ' 个'
            console.log(s)
            this.$alert(s, '考试结果', {
                confirmButtonText: '确定',
                callback: action => {
                    this.reload_sunjects()
                }
            })

        },
        uuid_info() {
            this.$notify.info({
                title: '消息',
                message: this.question[0].uuid
            })
        },
        sleep(ms) { //sleep延迟方法2
            var unixtime_ms = new Date().getTime();
            while (new Date().getTime() < unixtime_ms + ms) { }
        },
        yuyin() {

            const mp3 = this.question[0].mp3
            let arr = JSON.parse(JSON.stringify(mp3))

            var myAudio = new Audio();
            myAudio.preload = true;
            myAudio.controls = true;
            myAudio.src = arr.pop(); // 每次读数组最后一个元素
            myAudio.addEventListener('ended', playEndedHandler, false);
            myAudio.play();
            console.log(arr.length)
            myAudio.loop = false; // 禁止循环，否则无法触发ended事件
            function playEndedHandler() {
                myAudio.src = arr.pop();
                myAudio.play();
                !arr.length && myAudio.removeEventListener('ended', playEndedHandler, false);//只有一个元素时解除绑定
            }

        },
        videoed() {
            if (this.question[0].video === '') {
                console.log('没有视频')
                this.$message('暂无视频讲解！');
            } else {
                this.centerDialogVisible = true
                this.videoSource = this.question[0].video
            }
        },
        vopen() {
            try {
                this.$refs.child[0].vstart();
                //播放
            } catch (error) {
                console.log('第一次打开视频异常问题');
                console.log(error)
            }

        },
        // 关闭dialog同时关闭视频
        vclose() {
            console.log(this.$refs.child)
            this.$refs.child[0].vstop();
        },

        sert(info) {
            let status = true
            this.$msgbox.confirm('正确答案是: ' + info, '提示', {
                confirmButtonText: '确定',
                type: 'warning',
                showClose: false,
                showCancelButton: false,
                closeOnClickModal: false,
                closeOnPressEscape: false,
            }).then(() => {
                // this.next()
                status = false

            });
            //这里就可以自动关闭弹窗了
            setTimeout(() => {
                if (status) {
                    this.$msgbox.close()
                    // this.next()
                }


            }, 10000);
        },

        sert_1(info) {
            let status = true
            this.$msgbox.confirm('正确答案是: ' + info, '提示', {
                confirmButtonText: '确定',
                type: 'warning',
                showClose: false,
                showCancelButton: false,
                closeOnClickModal: false,
                closeOnPressEscape: false,
            }).then(() => {
                // this.next()
                status = false
            });
            //这里就可以自动关闭弹窗了
            setTimeout(() => {
                if (status) {
                    this.$msgbox.close()
                    // this.next()
                }


            }, 10000);
        },
        judgment() {
            this.canclick = true
            console.log('点击了')

            let val = this.judgmentvalue
            let answer = this.question[0].answer
            let on_number = this.question[0].sequence_number

            let info = this.question[0]
            let one = {}
            let uuid = info.uuid
            one['canclick'] = this.canclick
            one['judgmentvalue'] = val

            this.sub_value[uuid] = one

            console.log(this.sub_value)

            if (val == answer) {
                console.log('答对了')
                this.tagstatus[on_number - 1] = 'success'
                this.zhengque = this.zhengque + 1
                console.log(this.tagstatus, 'dfdfdf')
                // 返回答题信息记录
                this.sub_answer(uuid, 'T')
                // 答对了直接跳到下一题
                // this.next()
            } else {
                console.log('答错了')
                console.log(answer)
                this.color_set = 'color_set'
                this.tagstatus[on_number - 1] = 'danger'
                this.cuowu = this.cuowu + 1
                this.sert(answer)
                // 返回答题信息记录
                this.sub_answer(uuid, 'F')
                if (answer === '对') {
                    this.iconstatus1 = 'iconshow'
                    one['iconstatus1'] = 'iconshow'
                } else {
                    this.iconstatus2 = 'iconshow'
                    one['iconstatus2'] = 'iconshow'
                }
            }


        },
        single() {
            this.canclick = true
            console.log('点击了')

            let val = this.singlevalue
            let info = this.question[0]
            let one = {}
            let uuid = info.uuid
            one['canclick'] = this.canclick
            let on_number = this.question[0].sequence_number


            let answer = this.question[0].answer
            console.log(answer)

            one['singlevalue'] = val
            this.sub_value[uuid] = one

            console.log(this.sub_value)

            if (val == answer) {
                console.log('答对了')
                this.tagstatus[on_number - 1] = 'success'
                this.singlevalue = val
                this.zhengque = this.zhengque + 1

                // 返回答题信息记录
                this.sub_answer(uuid, 'T')
                // 答对了直接跳到下一题
                // this.next()
            } else {
                console.log('答错了')
                this.color_set = 'color_set'
                this.tagstatus[on_number - 1] = 'danger'
                this.singlevalue = val
                this.singlevalueicon = answer
                one['singlevalueicon'] = answer
                this.cuowu = this.cuowu + 1
                let select = ''
                // 返回答题信息记录
                this.sub_answer(uuid, 'F')
                if (this.question[0].option_a.info == answer) {
                    select = '选项A'
                } else if (this.question[0].option_b.info == answer) {
                    select = '选项B'
                } else if (this.question[0].option_c.info == answer) {
                    select = '选项C'
                } else if (this.question[0].option_d.info == answer) {
                    select = '选项D'
                }

                this.sert(select)
            }


        },
        submitForm(val) {
            let answer = this.question[0].answer
            let arr = answer.split("; ")
            console.log(this.multiplevalue)
            this.canclick = true

            // 存储提交过的题目状态
            let info = this.question[0]
            let one = {}
            let uuid = info.uuid
            one['canclick'] = this.canclick

            one['multiplevalue'] = this.multiplevalue
            this.sub_value[uuid] = one

            console.log(this.sub_value)

            let on_number = this.question[0].sequence_number

            if (arr.length === this.multiplevalue.length && arr.sort().toString() === this.multiplevalue.sort().toString()) {
                console.log('答对了')
                this.tagstatus[on_number - 1] = 'success'
                //this.multipleanswer = arr
                this.zhengque = this.zhengque + 1

                this.sub_answer(uuid, 'T')

                // 答对了直接跳到下一题
                this.next()
            } else {
                console.log('答错了, 显示正确答案')
                this.tagstatus[on_number - 1] = 'danger'
                this.cuowu = this.cuowu + 1
                this.color_set = 'color_set'
                this.sub_answer(uuid, 'F')

                let select = ''
                if (arr.indexOf(this.question[0].option_a.info) >= 0) {
                    select = select + '选项A; '
                }
                if (arr.indexOf(this.question[0].option_b.info) >= 0) {
                    select = select + '选项B; '
                }
                if (arr.indexOf(this.question[0].option_c.info) >= 0) {
                    select = select + '选项C; '
                }
                if (arr.indexOf(this.question[0].option_d.info) >= 0) {
                    select = select + '选项D; '
                }
                if (val == 1) {
                    this.sert_1(select)
                } else {
                    this.sert(select)
                }
            }

        },
        shangyiti(questionType) {
            console.log('上一题')
            console.log(questionType)
            let on_number = this.question[0].sequence_number
            if (on_number == 1) {
                this.$message({
                    showClose: true,
                    message: '已经是第一题了！',
                    type: 'warning'
                })
            } else {
                console.log('进来了', on_number)
                let uuid = this.question[0].uuid
                let subject = this.sub_value[uuid]
                if (questionType == 3 && this.multiplevalue.length != 0 && typeof (subject) == "undefined") {
                    this.submitForm(1)
                }
                this.question = [this.sum_question[on_number - 2]]
                this.nextone()
            }
        },
        xiyiti(questionType) {
            let uuid = this.question[0].uuid
            let subject = this.sub_value[uuid]
            console.log(subject)
            console.log(this.multiplevalue)
            if (typeof (subject) == "undefined") {
                if (questionType == 1 && this.singlevalue != '') {
                    console.log('选了单选题')
                    // this.single()
                    this.next()
                } else if (questionType == 2 && this.judgmentvalue != '') {
                    console.log('选择了判断题')
                    // this.judgment()
                    this.next()
                } else if (questionType == 3 && this.multiplevalue.length != 0) {
                    console.log('选择了多选题')
                    this.submitForm(2)
                } else {
                    this.next()
                }
            } else {
                this.next()
            }



        },
        next() {
            console.log('下一题')
            console.log(this.question[0])
            let on_number = this.question[0].sequence_number
            if (on_number == this.sum_question.length) {
                this.$message({
                    showClose: true,
                    message: '已经是最后一题了！',
                    type: 'warning'
                })
            } else {
                this.question = [this.sum_question[on_number]]
                this.nextone()
            }
        },
        nextone() {
            // 判断是否是做过的题
            let uuid = this.question[0].uuid
            let image_time = this.question[0].image_time
            let subject = this.sub_value[uuid]
            this.show_photo = 'box'
            this.color_set = 'color_set_o'
            console.log(subject)
            if (typeof (subject) == "undefined") {
                console.log('没做过')
                // 开始下一题初始化个别参数
                this.noskill = 'noskill'
                this.showskill = 'showskill'
                this.iconstatus1 = 'iconstatus1'
                this.iconstatus2 = 'iconstatus2'
                this.skill = false
                this.yuyinbtn = false
                this.centerDialogVisible = false
                this.judgmentvalue = ''
                this.canclick = false
                this.singlevalue = ''
                this.singlevalueicon = ''
                this.multiplevalue = []
                this.multipleanswer = []
                if (image_time > 0) {
                    setTimeout(() => {
                        //需要定时执行的代码
                        console.log("Hello World");
                        this.show_photo = 'pirture'
                    }, image_time * 1000)
                }

            } else {
                console.log('做过了')
                this.canclick = subject.canclick
                this.judgmentvalue = subject.judgmentvalue
                this.singlevalue = subject.judgmentvalue
                console.log(this.singlevalue)
                // 判断题
                if (typeof (subject.iconstatus2) != 'undefined') {
                    this.iconstatus2 = subject.iconstatus2
                    this.iconstatus1 = 'iconstatus1'
                }
                else if (typeof (subject.iconstatus1) != 'undefined') {
                    this.iconstatus1 = subject.iconstatus1
                    this.iconstatus2 = 'iconstatus2'
                } else {
                    this.iconstatus1 = 'iconstatus1'
                    this.iconstatus2 = 'iconstatus2'
                }

                // 单选题
                this.singlevalue = subject.singlevalue
                if (typeof (subject.singlevalueicon) != 'undefined') {
                    this.singlevalueicon = subject.singlevalueicon
                } else {
                    this.singlevalueicon = ''
                }

                //多选题
                if (typeof (subject.multipleanswer) != 'undefined') {
                    this.multipleanswer = subject.multipleanswer
                } else {
                    this.multipleanswer = []
                }
                if (typeof (subject.multiplevalue) != 'undefned') {
                    this.multiplevalue = subject.multiplevalue
                } else {
                    this.multiplevalue = []
                }
            }


        },
        sub_answer(uuid_code, status_) {
            answer_question_pc({ exercise_code: uuid_code, status: status_, key: this.pc_key, this_code: this.this_code }).then(({ data }) => {
                if (data.code == 200) {
                    console.log(data);
                } else {
                    console.log(data);
                }
            })
        },
        // 计算百分比
        gteProgress(val1, val2) {
            // console.log(val1, '被除数', val2, '除数');
            if (val1 == 0 || val2 == 0) {
                return 0
            }
            console.log((Math.round(val1 / val2 * 10000) / 100.00))
            return (Math.round(val1 / val2 * 10000) / 100.00)
        },
        zhengquelv() {
            return this.gteProgress(this.zhengque, this.zhengque + this.cuowu)
        },
        datilv() {
            return this.gteProgress(Object.keys(this.sub_value).length, this.sum_question.length)
        },
        range(val1, val2) {
            // console.log(val1, val2)
            const _list = []
            for (var i = val1 + 1; i <= val2; i++) {
                _list.push(i)
            }
            // console.log(_list)
            return _list
        },
        agina_init() {
            this.tagstatus = [],
                this.sub_value = {},
                this.zhengque = 0,
                this.cuowu = 0,
                this.canclick = false,
                this.skill = false,
                this.yuyinbtn = false,
                this.centerDialogVisible = false,
                this.judgmentvalue = '',
                this.singlevalue = '',
                this.singlevalueicon = '',
                this.multiplevalue = [],
                this.multipleanswer = []
        },
        reload_sunjects() {
            parcticeinfo_weixin({ params: this.again_sub }).then(({ data }) => {
                if (data.code == 200) {
                    if (data.count == 0) {
                        this.$message({
                            showClose: true,
                            message: '没有可重做的错题!',
                            type: 'warning',
                            duration: 10000
                        })
                        return
                    }
                    this.agina_init()
                    this.sum_question = data.data
                    this.question = [data.data[0]]
                    console.log(this.question[0].option_a.info)
                    for (var i = 0; i < this.sum_question.length; i++) {
                        this.tagstatus.push('info')

                    }
                    this.isloading = false
                    this.this_code = data.this_code
                    console.log(this.this_code)
                } else {
                    this.$message({
                        showClose: true,
                        message: data.msg,
                        type: 'warning',
                        duration: 10000

                    })
                }

            })
        },
        refresh() {
            this.$confirm('此操作将打乱当前试题顺序, 并重置目前答题结果, 已做错题会保留错题记录, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                const new_data = this.random_set(this.sum_question)
                // console.log(new_data)
                this.agina_init()
                this.sum_question = new_data
                this.question = [new_data[0]]
                console.log(this.question[0].option_a.info)
                for (var i = 0; i < this.sum_question.length; i++) {
                    this.tagstatus.push('info')
                }

            }).catch((error) => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
                console.log(error)
            });
        },
        random_set(val) {
            var cloneArr = val.concat();
            var len = cloneArr.length;
            for (var i = 0; i < len; i++) {
                var index = Math.floor(Math.random() * cloneArr.length);
                var temp = cloneArr[index];
                cloneArr[index] = cloneArr[i];
                temp['sequence_number'] = i + 1;

                cloneArr[i] = temp;
                // console.log(i)
                // console.log(temp)

            }
            var new_list = []
            for (var i = 0; i < len; i++) {
                var d = cloneArr[i];
                d['sequence_number'] = i + 1;
                new_list.push(d)
            }
            return new_list
        }

    },
    mounted() {


        window.onresize = () => (() => {
            this.screenWidth = document.body.clientWidth;
        })();
        if (this.screenWidth < 1600) {
            // 屏幕宽度小于768
            this.timelineHeight = '42vh';
            console.log('小于')
            this.btn_size = 'mini';
            this.jindu_gao = 16
        } else {
            // 其余尺寸
            this.timelineHeight = '700px';
            console.log('大于')
            this.btn_size = '';
            this.jindu_gao = 26
        }

        let car = this.$route.query.car_model
        let subject = this.$route.query.subjects
        let class_name = this.$route.query.class_name
        this.pc_key = this.$route.query.key
        this.browser_type = this.$route.query.browser_type
        console.log(this.$route.query)
        if (typeof (class_name) == 'undefined') {
            class_name = this.$route.query.restart
        }
        this.titleinfo = car + '-' + subject + '-' + class_name
        parcticeinfo_weixin({ params: this.$route.query }).then(({ data }) => {
            if (data.code == 200) {
                if (data.count == 0) {
                    this.$message({
                        showClose: true,
                        message: '没有可重做的错题!',
                        type: 'warning',
                        duration: 10000

                    })
                }
                this.user_name = data.user_name
                this.sum_question = data.data
                this.question = [data.data[0]]
                console.log(this.question[0].option_a.info)
                for (var i = 0; i < this.sum_question.length; i++) {
                    this.tagstatus.push('info')

                }
                this.isloading = false
                this.this_code = data.this_code
                // this.again_sub.push({car:car, subjects: subject, class_name: class_name, pc_key:this.pc_key, this_code: this.this_code})
                this.again_sub.car_model = car
                this.again_sub.subjects = subject
                this.again_sub.class_name = class_name
                this.again_sub.key = this.pc_key
                this.again_sub.this_code = this.this_code
                console.log(this.again_sub)
                console.log(this.this_code)
            } else {
                this.$message({
                    showClose: true,
                    message: data.msg,
                    type: 'warning',
                    duration: 10000

                })
            }

        })


    },
    created() {
        new DevicePixelRatio().init();
    },
    watch: {
        screenWidth(val) {
            this.screenWidth = val;
            if (this.screenWidth < 1600) {
                // 宽度小于768
                this.timelineHeight = '42vh';
                console.log('小于')
                this.btn_size = 'mini';
                this.jindu_gao = 16
            } else {
                // 其余尺寸
                this.timelineHeight = '700px';
                this.btn_size = '';
                this.jindu_gao = 26
            }
        }
    },
};
</script>

<style lang="less" scoped>
.color_set {
    font-size: 25px;

    /deep/ span {
        color: red
    }
}

.color_set_o {
    font-size: 25px;
}

.box-left {
    img {
        margin-left: 25%;
        width: 180px;
        height: 180px;
        border-radius: 10%;

    }

    .spaninfo {
        text-decoration: underline;
        color: #999999;
    }

    h4 {
        padding-left: 25%;
        font-size: 22px;
    }

    h2 {
        font-size: 25px;
    }

    p {
        font-size: 30px;
    }

}

.VSTD_box_item {
    .VSTD_box_item_title {
        span {
            font-size: 25px;
        }

        height: 400px;
        overflow: auto;
        position: relative;
        z-index: 99;
    }

    .select_option {
        display: none;

        p {
            font-size: 25px;
        }

        width: 100%;

    }
}


.VSTD_box_item_select {
    // 修改选项框大小,字体颜色,上下间隙
    zoom: 140%;

    .el-radio {
        color: #000;
        padding-top: 20px;
    }
}

.box-right {

    .tagbtn {
        padding-left: 10px;
    }
}

.demo-ruleForm {

    // 处理选项款不靠左贴边问题
    /deep/.el-form-item__content {
        margin-left: 0px !important;
        height: 100%;
    }
}

.pirture {
    display: none;
}

.Apirture {
    // width: 380px;
    height: 210px;
    object-fit: cover;

}

.noskill {
    display: none;
}

.showskill {
    font-size: 25px;
    color: red;

}

.iconstatus1 {
    display: none;
}

.iconstatus2 {
    display: none;
}

.iconshow {
    display: inline;
}

.el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
}

.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
}

.box-card {
    height: 380px;
    overflow-y: auto
        /* 开启滚动显示溢出内容 */
}



::v-deep.el-radio-button {
    margin-right: 7px;
    border-radius: 2px;
    border: 1px solid black;

    .el-radio-button__inner {
        //修改按钮样式
        // width: 5px;
        // height: 17px;
        background: #F7F8FA;
        color: #333;

        border: 0 !important;
    }

    .el-radio-button__orig-radio:checked+.el-radio-button__inner {
        // 修改按钮激活样式
        color: #fff;
        background-color: red;
        border-color: red;
        box-shadow: -1px 0 0 0 #f63;
    }
}

::v-deep.el-checkbox-button {

    margin-right: 7px;
    border-radius: 2px;
    border: 1px solid black;

    span {
        height: 100%;
    }

    .el-checkbox-button__inner {
        //修改按钮样式
        // width: 5px;
        // height: 17px;
        background: #F7F8FA;
        color: #333;
        padding: 8px 10px;
        border: 0 !important;
    }

    .el-checkbox-button__original:checked+.el-checkbox-button__inner {
        // 修改按钮激活样式
        color: #fff;
        background-color: red;
        border-color: red;
        box-shadow: -1px 0 0 0 #f63;
    }

}

.box {
    height: 420px;
    // background-color: pink;
    display: flex;
    justify-content: center;
    align-items: center;
}

.select {
    font-size: 20px;
    width: 100%;
    position: absolute;
    top: 400px;
}

.your_answer {
    font-size: 20px;
}

.problem {
    height: 500px;
    position: relative;
}

.tag_set {
    margin-right: 10px;
    margin-top: 3px;
    width: 40px
}

.lunbotu {
    margin-bottom: 40px;
}

.lunbotu_gao {
    height: 220px;
}

@media screen and (min-width:1024px) and (max-width:1500px) {

    .VSTD_box_item {
        .VSTD_box_item_title {
            span {
                font-size: 20px;
            }

            height: 260px;
            overflow: auto;
            position: relative;
            z-index: 99;
        }



        .select_option {
            display: none;

            p {
                font-size: 15px;
            }

            width: 100%;

        }

        .btn_click {
            // font-size: 20px;
            width: 477px;
            position: absolute;
            top: 370px;
        }
    }

    .VSTD_box_item_select {
        // 修改选项框大小,字体颜色,上下间隙
        zoom: 100%;

        .el-radio {
            color: #000;
            padding-top: 20px;
        }
    }


    .your_answer {
        font-size: 12px;
    }


    .select {
        font-size: 20px;
        width: 100%;
        position: absolute;
        top: 250px;
    }

    .problem {
        height: 280px;
    }

    .box {
        height: 250px;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 140px;
    }


    .Apirture {
        // width: 380px;
        height: 150px;
        object-fit: cover;

    }

    .lunbotu_gao {
        height: 150px;
    }

    .tag_set {
        margin-right: 8px;
        margin-top: 5px;
        width: 25px;

    }

    .el-tag {
        height: 18px;
        padding: 2px 2px;
        line-height: 10px;
    }

    .box-card {
        height: 260px;
        overflow-y: auto
            /* 开启滚动显示溢出内容 */
    }

    .fenge {
        display: none;
    }

    .lunbotu {
        margin-bottom: 15px;
    }

    .box-left {
        img {
            margin-left: 33%;
            width: 90px;
            height: 90px;
            border-radius: 10%;

        }

        .spaninfo {
            text-decoration: underline;
            color: #999999;
        }

        h4 {
            padding-left: 20%;
            font-size: 13px;
        }

        h2 {
            font-size: 17px;
        }

        p {
            font-size: 20px;
        }

    }

    .showskill {
        font-size: 18px;
        color: red;

    }

    .next_one {
        padding-top: 8px;
    }

    ::v-deep.el-radio-button {
        margin-right: 7px;
        border-radius: 2px;
        border: 1px solid black;

        .el-radio-button__inner {
            //修改按钮样式
            // width: 5px;
            // height: 17px;
            background: #F7F8FA;
            color: #333;

            border: 0 !important;
        }

        .el-radio-button__orig-radio:checked+.el-radio-button__inner {
            // 修改按钮激活样式
            color: #fff;
            background-color: red;
            border-color: red;
            box-shadow: -1px 0 0 0 #f63;
        }
    }

    ::v-deep.el-checkbox-button {

        margin-right: 7px;
        border-radius: 2px;
        border: 1px solid black;

        span {
            height: 100%;
        }

        .el-checkbox-button__inner {
            //修改按钮样式
            // width: 5px;
            // height: 17px;
            background: #F7F8FA;
            color: #333;
            padding: 13px 13px;
            border: 0 !important;
        }

        .el-checkbox-button__original:checked+.el-checkbox-button__inner {
            // 修改按钮激活样式
            color: #fff;
            background-color: red;
            border-color: red;
            box-shadow: -1px 0 0 0 #f63;
        }

    }



}
</style>